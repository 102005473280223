<template>
    <v-container fluid>
        <v-row>
            <v-col cols="2"> <settingDrawer /> </v-col>
            <v-col>
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import settingDrawer from '@/components/drawers/drawer-settings';

export default {
    name: 'settings-layout',
    data: () => ({}),
    components: { settingDrawer },
    created() {},
    computed: {},
    methods: {},
};
</script>

<style></style>
