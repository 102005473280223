<template>
    <app-drawer
        :menus="menus"
        :allow-mini="false"
        :clipped="false"
        :dark="false"
        :floating="true"
        color="transparent"
        :app="false"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-settings',
    data: () => ({
        mini: true,
    }),
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['profile']),
        allMenus() {
            return [
                {
                    to: 'settings-users',
                    icon: 'mdi-account',
                    label: 'drawer.settings.users',
                    position: 'top',
                    group: 'drawer.settings.directory',
                },
                {
                    to: 'settings-profiles',
                    icon: 'mdi-account-group',
                    label: 'drawer.settings.profiles',
                    position: 'top',
                    group: 'drawer.settings.directory',
                },
                {
                    to: 'settings-trackers',
                    label: 'drawer.settings.trackers',
                    icon: 'mdi-bug',
                    group: 'drawer.settings.issues',
                    position: 'top',
                },
                {
                    to: 'settings-status',
                    label: 'drawer.settings.status',
                    icon: 'mdi-clipboard-list',
                    group: 'drawer.settings.issues',
                    position: 'top',
                },
                {
                    to: 'settings-priorities',
                    label: 'drawer.settings.priorities',
                    icon: 'mdi-flag',
                    group: 'drawer.settings.issues',
                    position: 'top',
                },
                {
                    to: 'settings-severities',
                    label: 'drawer.settings.severities',
                    icon: 'mdi-bell-alert',
                    group: 'drawer.settings.issues',
                    position: 'top',
                },
            ];
        },
        menus() {
            return this.allMenus;
        },
    },
};
</script>
